import React, { useState } from "react"

import AgeCalculator from "../components/agegate-component"

const AgeGate = props => {
  const [isOldError, setIsOldError] = useState(false)
  const [ageError, setAgeError] = useState(false)
  return (
    <>
      <div className="page-heading">
        <h1 className="text-uppercase text-lg">Are You Over 16?</h1>

        <div className="agegate-hldr">
          <AgeCalculator
            setIsOldError={setIsOldError}
            setAgeError={setAgeError}
            setAgeGateShow={props.setAgeGateShow}
          />
          {isOldError && (
            <p className="text-center input-error py-4 mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Oops, please complete your details then press enter.
            </p>
          )}
          {ageError && (
            <p className="text-center input-error py-4 mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Sorry you aren't allowed to enter this site.
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default AgeGate
